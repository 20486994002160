import { Paper } from "@mantine/core";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import moment from "moment";
import { Draggable } from "react-beautiful-dnd";
import { Link } from "react-router-dom";
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isBetween);

export function getWeekDays(date) 
{

  const start = moment(date).startOf("week");
  const end = moment(date).endOf("week");

  console.log('start', start);

  const days = Array.from({ length: 7 }, (_, i) => moment(start).add(i, "day"));


  return {
    start,
    end,
    days,
    startFormatted: start.format("YYYY-MM-DD"),
    endFormatted: end.format("YYYY-MM-DD"),
  };
}

export function isInWeekRange(date, weekStart) {
  if (!weekStart) return false;
  const start = dayjs(weekStart)
    .tz(process.env.REACT_APP_TIMEZONE)
    .startOf("week");
  const end = dayjs(weekStart).tz(process.env.REACT_APP_TIMEZONE).endOf("week");
  return dayjs(date)
    .tz(process.env.REACT_APP_TIMEZONE)
    .isBetween(start, end, null, "[]");
}

export const statusColors = {
  planned: "orange",
  done: "green",
  canceled: "black",
  unverified: "grey",
  no_show: "red",
  rescheduled: 'white',
};

export const timeSlots = Array.from({ length: 16 }, (_, i) =>
  dayjs()
    .tz(process.env.REACT_APP_TIMEZONE)
    .hour(9).minute(30).second(0)
    .add(i * 30, "minute")
);

export const formatPhone = (phone) => {
  return phone.replace(/(\d{1})(\d{3})(\d{3})(\d{3})/, "+$1 ($2) $3 - $4");
};

export const renderAppointment = (appointments, openModal) => {

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "0.4rem" }}>
      {appointments.map((appointment, index) => ( <Draggable
                            key={appointment.id}
                            draggableId={appointment.id.toString()}
                            index={appointment.index}
                          >

                          {(provided) => (
                              <div
                                className="draggable-item"
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                ref={provided.innerRef}
                                style={{
                                  margin: '4px 0',
                                  borderRadius: 4,
                                  ...provided.draggableProps.style,
                                }}
                              >

                                <Paper
                                  p="xs"
                                  bg={`${statusColors[appointment.status]}.2`}
                                  className="timeslot-appointment"
                                  onClick={() => openModal(appointment)}
                                  style={{
                                    border: `1px solid ${statusColors[appointment.status]}`,
                                    flex: 1,
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                  }}
                                >
                                  <p>{appointment.name}</p>
                                </Paper>
                                
                              </div>
                            )}
        </Draggable>
      ))}
    </div>
  );
};
